@import url(//fonts.googleapis.com/earlyaccess/notokufiarabic.css);

:root {
  --fontArabic: "Noto Kufi Arabic", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
*:focus {
  outline: none;
  box-shadow: none;
}

body {
  background-color: #eff3f5;
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/** MOBILE VERSION **/

.wrapper {
  max-width: 640px;
  min-height: 100vh;
  background-color: #eff3f5;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c__player {
  padding: 10px;
  max-width: 600px;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c__player > * {
  padding: 10px;
  margin: 10px 0px;
}

.song__status p {
  text-transform: capitalize;
  color: #5f7397;
  text-align: center;
}

.border__img {
  border: 2px dotted #4030ff;
  width: 210px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
}

.img__frame {
  width: 200px;
  height: 200px;
  padding: 0 !important;
}

.img__frame > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.c__player__details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c__player__details h3 {
  color: #2a3b5c;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
  font-family: var(--fontArabic);
}

.c__player__details p {
  color: #93a1b9;
  text-align: center;
}

/*! Controls */
.c__player__controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.controls > * {
  font-size: 40px !important;
  color: #2a3b5c;
}

.controls > *:hover {
  cursor: pointer;
}

.play__pause {
  background-color: #4030ff;
  color: #fff;
  border-radius: 10px;
  padding: 5px;
  transition: background-color 0.2s ease-in-out !important;
}

.play__pause:hover {
  background-color: #1b0ccc;
  transition: background-color 0.2s ease-in-out;
}

.forward__backward {
  color: #4030ff;
  transition: transform 0.3s ease-in-out;
}

.backward,
.forward {
  transition: transform 0.3s ease-in-out !important;
}

.backward:hover {
  color: #1b0ccc;
  transition: color 0.2s ease-in-out;
}

.forward:hover {
  color: #1b0ccc;
  transition: color 0.2s ease-in-out;
}

.next__song {
  color: #5c77a5;
  font-size: 1.1rem;
  text-align: center;
}

#next__song {
  font-family: var(--fontArabic);
  font-weight: bold;
}

.previous__songs:hover,
.next__songs:hover {
  color: #1b0ccc;
  transition: background-color 0.2s ease-in-out;
}

/**! Progress bar **/
.c__player__time {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.range__slider {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
}

.start__timer {
  color: #1b0ccc;
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: 8px;
}

.duration__timer {
  color: #1b0ccc;
  font-size: 0.9rem;
  font-weight: bold;
  margin-left: 8px;
}

#slider {
  -webkit-appearance: none;
  width: calc(70%);
  height: 7px;
  border-radius: 5px;
  background: #d6d8dad3;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
#slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
/*
#slider::-webkit-slider-thumb:hover {
  background: #d4d4d4;
  transform: scale(1.2);
}
*/
#slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
#slider::-moz-range-thumb:hover {
  background: #d4d4d4;
}
